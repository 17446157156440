import { createContext, useContext } from 'react';
import { app } from 'backend/firebase'
import { getFirestore, collection, getDocs, setDoc, doc } from 'firebase/firestore/lite'


export type Worker = {
    id: number,
    name: string,
    engagements: Engagement[],
    profile_pic: string
}
export type Engagement = {
    startDate: Date,
    endDate?: Date
    role: string
    checks: Check[]
}
export type Check = {
    name: string,
    status: "completed" | "inprogress" | "failed"
}
interface WorkforceProviderType {
    getWorkers(): Promise<Worker[]>
    addWorker(worker: Worker): Promise<void>
}
class FirebaseWorkforceProvider implements WorkforceProviderType {
    public async getWorkers(): Promise<Worker[]> {

        const db = getFirestore(app)
        const workersCollection = collection(db, 'workers')
        const workersSnapshot = await getDocs(workersCollection)

        const workers = workersSnapshot.docs.map(doc => {
            return { id: parseInt(doc.id), ...doc.data() } as Worker
        });
        console.log(workers)
        return workers
    }
    public async addWorker(worker: Worker): Promise<void> {
        const db = getFirestore(app)
        const docRef = doc(db, 'workers', worker.id.toString())

        await setDoc(docRef, worker)
    }
}
const WorkforceContext = createContext<WorkforceProviderType>(new FirebaseWorkforceProvider());
export const useWorkforce = () => useContext(WorkforceContext);

export function WorkforceProvider({ children }) {
    return (
        <WorkforceContext.Provider value={new FirebaseWorkforceProvider()}>
            {children}
        </WorkforceContext.Provider>
    )
}
