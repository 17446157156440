import React from "react";

const Page1 = React.lazy(() => import("./Page1"));
const Workforce = React.lazy(() => import("./Workforce"));
export const samplePagesConfigs = [
  {
    path: "/sample/page-1",
    element: <Page1 />,
  },
  {
    path: "/workforce",
    element: <Workforce />,
  },
];
