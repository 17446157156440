import { initializeApp  } from 'firebase/app'

// Initialize Firebase

export const firebaseConfig = {
  apiKey: "AIzaSyB5FNIpsZikWOr-Md4GJD5--HjSheJTWzo",
  authDomain: "compliance-cloud-d28fb.firebaseapp.com",
  projectId: "compliance-cloud-d28fb",
  storageBucket: "compliance-cloud-d28fb.appspot.com",
  messagingSenderId: "459676015927",
  appId: "1:459676015927:web:f9ef7e01b584290f010eb2",
  measurementId: "G-FE27Y8ZHXW"
};

export const app = initializeApp(firebaseConfig);
